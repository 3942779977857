import { useParams } from '@solidjs/router'
import ChapterCreateForm from '~/components/forms/chapter-create/chapter-create'
import Header from '~/components/header/header'
import { SeriesParams } from '~/types/routes/series/entry'

export default function CreateChapter() {
  const { id } = useParams<SeriesParams>()
  return (
    <>
      <Header />
      <ChapterCreateForm comicId={id} />
    </>
  )
}
