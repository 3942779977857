import { Component, createEffect, createResource } from 'solid-js'
import { SubmitHandler, createForm, valiForm, setValue, setResponse } from '@modular-forms/solid'
import TextField from '~/components/fields/text/text'
import Button from '~/components/button/button'
import styles from '~/components/forms/styles/complex-form.module.scss'
import FormError from '~/components/form-error/form-error'
import { useNavigate } from '@solidjs/router'
import { createChapter } from '~/services/database/chapters'
import { ChapterCreateFormProps, ChapterCreateFormType, ChapterCreateSchema } from '~/components/forms/chapter-create/chapter-create.interfaces'
import { PostgrestError } from '@supabase/supabase-js'
import ImagesUploadField from '~/components/images-upload-field/images-upload-field'
import { getLastChapter, getStartingStep } from '~/services/database/series'
import { setLoadingState } from '~/services/loading/loading'
import DocumentIcon from '~/assets/icons/document-empty.svg'
import { translations } from '~/translations'

const ChapterForm: Component<ChapterCreateFormProps> = ({ comicId }) => {
  const navigate = useNavigate()
  const [lastChapter] = createResource(comicId, getLastChapter)

  const [form, { Form, Field }] = createForm<ChapterCreateFormType>({
    validate: valiForm(ChapterCreateSchema),
    initialValues: {
      index: (lastChapter.latest?.index ?? 1).toString()
    }
  })

  createEffect(() => {
    const index = lastChapter()?.index ?? 0
    setValue(form, 'index', (index + 1).toString())
  })

  const onSubmit: SubmitHandler<ChapterCreateFormType> = async (values) => {
    setLoadingState({
      loading: true,
      message: translations().loading.uploadingImages
    })
    const progress = startingStep.latest?.value ?? 'translation'
    const payload = {
      ...values,
      progress,
      comicId
    }
    await createChapter(payload).then(chapterId => {
      navigate(`/series/chapter/${chapterId}/${progress}`)
    }).catch(error => {
      setResponse(form, {
        status: 'error',
        message: (error as PostgrestError).message
      })
    }).finally(() => {
      setLoadingState({
        loading: false
      })
    })
  }

  const [startingStep] = createResource(comicId, getStartingStep)

  return (
    <Form onSubmit={onSubmit} class={styles.form}>
      <div class={styles.head}>
        <div class={styles.primaryTitle}>
          {translations().chapters.forms.create.title}
        </div>
      </div>
      <div class={styles.fieldColumnGroup}>
        <div class={styles.fieldColumn}>
          <Field name="index">
            {(field, props) => (
              <TextField
                label={translations().chapters.forms.create.chapterIndex}
                {...field}
                {...props}
              />
            )}
          </Field>
        </div>
        <div class={styles.fieldColumn}>
          <Field name="title">
            {(field, props) => (
              <TextField
                label={`${translations().chapters.forms.create.chapterTitle} (${translations().general.fields.optional})`}
                {...field}
                {...props}
              />
            )}
          </Field>
        </div>
      </div>
      <div class={styles.fieldColumnGroup}>
        <div class={styles.fieldColumn}>
          <Field name="pagesOriginal" type="File[]">
            {(field, props) => (
              <ImagesUploadField
                {...field}
                {...props}
                label={translations().chapters.forms.create.sourcePages}
              />
            )}
          </Field>
        </div>
        <div class={styles.fieldColumn}>
          <Field name="pagesClean" type="File[]">
            {(field, props) => (
              <ImagesUploadField
                {...field}
                {...props}
                label={`${translations().chapters.forms.create.targetPages} (${translations().general.fields.optional})`}
              />
            )}
          </Field>
        </div>
      </div>
      <div class={styles.fieldColumnGroup}>
        <div class={styles.fieldColumn}>
          <div class={styles.fileUploadDescription}>
            <p>{translations().chapters.forms.create.guidelines1}</p>
            <ul>
              <li>
                <DocumentIcon class={styles.icon} />
                <span>series_title_chapter14_001.jpg</span>
              </li>
              <li>
                <DocumentIcon class={styles.icon} />
                <span>series_title_chapter14_002.jpg</span>
              </li>
              <li>
                <DocumentIcon class={styles.icon} />
                <span>series_title_chapter14_003.jpg</span>
              </li>
            </ul>
            <p>{translations().chapters.forms.create.guidelines2} : 8192px</p>
          </div>
        </div>
        <div class={styles.fieldColumn}>
          <div class={styles.fileUploadDescription}>
            <p>{translations().chapters.forms.create.aboutTeam(startingStep.latest?.label)}</p>
          </div>

          <FormError response={form.response} />

          <Button
            type='submit'
            style='lagoon'
            isLoading={form.submitting}
          >
            {translations().general.actions.create}
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default ChapterForm