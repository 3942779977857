import { InferOutput, array, instance, object, string, minLength, pipe, nonEmpty } from 'valibot'

export interface ChapterCreateFormProps{
  comicId: string
}

export const ChapterCreateSchema = object({
  index: pipe(string(), nonEmpty()),
  title: string(),
  pagesOriginal: pipe(array(instance(File)), minLength(1)),
  pagesClean: array(instance(File))
})

export type ChapterCreateFormType = InferOutput<typeof ChapterCreateSchema>